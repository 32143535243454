import { sanitizeWithoutHtml } from '@local/web-design-system/dist/components/HtmlSection/sanitizeHtml';
import L, { latLng } from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ConfigurationProject, ProjectBoundary, configurationState } from 'state-domains/domain';
import { debounce } from 'src/utilities';

import { convertIntToLabel } from '../MapBoundaryControls/MapBoundaryControls.utils';
import { convertToMapCoordinates } from '../MapUtils';

import { useStyles } from './MapBoundaries.styles';

export interface MapBoundaryButtonProps {
    map: any;
    projectBoundaries: ProjectBoundary[];
    showBounds: boolean;
    currentProject?: ConfigurationProject;
    showControls?: boolean;
    disabled?: boolean;
}

export const MapBoundaries = (props: MapBoundaryButtonProps) => {
    const {
        map,
        projectBoundaries,
        currentProject,
        showBounds,
        showControls = false,
        disabled = false,
    } = props;

    const cutPayloadRef = React.useRef<any>([]);

    const {
        actions: { editConfigurationProject },
    } = configurationState;

    const boundaryRef = React.useRef<any>(null);
    const markerRef = React.useRef<any>(null);

    const dispatch = useDispatch();
    const { classes } = useStyles();

    // ----------------- Event Handlers ------------------ //
    const createNewPayload = (coordinates: any, project: any) => {
        let targetName = `${sanitizeWithoutHtml(project.name).trim()} boundary`.trim();
        if (project.boundary?.features) {
            const allNames = project.boundary.features
                .filter((x: any) => x.properties.name.includes(targetName))
                .map((x: any) => x.properties.name.split(' ').slice(-1)?.[0])
                .sort((a: any, b: any) => a - b);
            if (allNames.length > 0) {
                let targetNum = 1;
                allNames.forEach((x: string) => {
                    const num = parseInt(x, 10);
                    if (num === targetNum) {
                        targetNum = num + 1;
                    }
                });
                targetName = `${targetName} ${targetNum}`;
            }
        }
        const newCoords = coordinates.map((x: any) => Object.values(x).reverse());

        let featuresPayload: any = [];
        if (project.boundary?.features) {
            featuresPayload = project.boundary.features;
        }
        return {
            ...project,
            boundary: {
                ...project.boundary,
                type: 'FeatureCollection',
                features: [
                    ...featuresPayload,
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [[...newCoords, newCoords[0]]],
                        },
                        properties: {
                            name: targetName,
                            isSaved: true,
                        },
                    },
                ],
            },
        };
    };

    const createEditPayload = (editedLayer: any, project: any) => {
        const currentFeatures = project.boundary.features;
        const newCoords = editedLayer.coords.map((layerGroup: any) => {
            const updatedBounds = layerGroup.map((bounds: any) => {
                const vals = Object.values(bounds);
                return [vals[1], vals[0]];
            });
            return [...updatedBounds, updatedBounds[0]];
        });
        currentFeatures[editedLayer.index] = {
            ...currentFeatures[editedLayer.index],
            geometry: {
                type: 'Polygon',
                coordinates: newCoords,
            },
        };

        return {
            ...project,
            boundary: {
                ...project.boundary,
                features: currentFeatures,
            },
        };
    };

    const onCreatePolygon = (e: any) => {
        // Adds layer to map which we dont want.
        // We want to add layer to layer group which gets done on rerender
        map.removeLayer(e.layer);
        const coordinates = e.layer._latlngs[0];
        if (currentProject) {
            const payload = createNewPayload(coordinates, currentProject);
            editConfigurationProject(payload, false)(dispatch);
        }
    };

    const onDeletePolygon = (e: any) => {
        const deletedLayer = e.layer.options.indexId;
        if (currentProject) {
            const currentFeatures = currentProject.boundary.features;
            const payload = {
                ...currentProject,
                boundary: {
                    ...currentProject.boundary,
                    features: currentFeatures.filter((_: any, idx: number) => deletedLayer !== idx),
                },
            };
            editConfigurationProject(payload, false)(dispatch);
        }
    };

    const onEditPolygon = (e: any) => {
        const editedLayer = {
            index: e.layer.options.indexId,
            coords: e.layer._latlngs,
        };
        if (currentProject) {
            const payload = createEditPayload(editedLayer, currentProject);
            editConfigurationProject(payload, false)(dispatch);
        }
    };

    // This compiles all the event calls into one payload since multiple events get called
    // for each polygon that was effected by the cut
    const cutPayload = useRef(
        debounce(
            () => {
                let fullPayload: any = {};
                cutPayloadRef.current.forEach((x: any) => {
                    fullPayload = {
                        ...fullPayload,
                        ...x,
                        boundary: {
                            ...(fullPayload?.boundary ?? {}),
                            features: Object.values({
                                ...(fullPayload?.boundary?.features ?? {}),
                                ...x.boundary.features,
                            }),
                        },
                    };
                });
                editConfigurationProject(fullPayload, false)(dispatch);
            },
            50,
            false,
        ),
    );

    const onCutPolygon = (e: any) => {
        const coords = e.layer._latlngs;
        let flag = false;
        let editedLayer = {};
        if (currentProject) {
            let payload = currentProject;
            if (coords[0]?.[0]?.[0]) {
                // Checks if hole is being created
                const newCoords = coords.map((x: any) => x[0]);
                newCoords.forEach((x: any) => {
                    if (flag) {
                        payload = createNewPayload(x, payload);
                    } else {
                        editedLayer = {
                            index: e.layer.options.indexId,
                            coords: [x],
                        };
                        payload = createEditPayload(editedLayer, payload);
                        flag = true;
                    }
                });
                payload = createEditPayload(editedLayer, payload);
            } else {
                editedLayer = {
                    index: e.layer.options.indexId,
                    coords: e.layer._latlngs,
                };
                payload = createEditPayload(editedLayer, payload);
            }
            // Adds layer to map which we dont want.
            // We want to add layer to layer group which gets done on rerender
            map.removeLayer(e.layer);
            cutPayloadRef.current.push(payload);
            cutPayload.current();
        }
    };

    // ----------------- Init Handlers ------------------ //

    const setCoordinatesForBoundary = (boundary: ProjectBoundary, idx: number) => {
        const mapCoords = boundary.coordinates.map((coord: any, idx: number) => {
            if (idx === 0 && showControls) {
                createMarkers(coord, markerRef);
            }
            return convertToMapCoordinates(coord).map((position) =>
                latLng(position[0], position[1]),
            );
        });
        const poly: any = new L.Polygon(mapCoords).bindTooltip(boundary.name, {
            permanent: true,
            direction: 'center',
            offset: [0, -20],
            opacity: 0.85,
        });
        poly.options.indexId = idx;
        poly.addTo(boundaryRef.current);
    };

    const boundarySetup = () => {
        boundaryRef.current = new L.FeatureGroup();
        markerRef.current = new L.FeatureGroup();
        boundaryRef.current.addTo(map);
        markerRef.current.addTo(map);
        projectBoundaries.forEach(setCoordinatesForBoundary);
    };

    const createMarkers = (coordArr: any, markerRef: any) => {
        coordArr.map((x: any, idx: number) => {
            if (idx < coordArr.length - 1) {
                L.marker([x[1], x[0]], {
                    icon: new L.DivIcon({
                        className: classes.iconStyles,
                    }),
                    draggable: false,
                })
                    .bindTooltip(convertIntToLabel(idx, ''), {
                        permanent: true,
                        direction: 'center',
                        offset: [0, -20],
                        opacity: 0.85,
                    })
                    .addTo(markerRef.current);
            }
        });
    };

    const initDrawActions = () => {
        map.on('pm:create', onCreatePolygon);
        map.on('pm:remove', onDeletePolygon);
        boundaryRef.current?.on('pm:markerdragend', onEditPolygon);
        boundaryRef.current?.on('pm:dragend', onEditPolygon);
        boundaryRef.current?.on('pm:cut', onCutPolygon);
    };

    const removeDrawActions = () => {
        map.off('pm:create');
        map.off('pm:remove');
        boundaryRef.current?.off('pm:markerdragend');
        boundaryRef.current?.off('pm:dragend');
        boundaryRef.current?.off('pm:cut');
    };

    React.useEffect(() => {
        if (map) {
            boundaryRef.current?.remove();
            markerRef.current?.remove();
            if (showBounds) {
                boundarySetup();
            }
        }
    }, [map, projectBoundaries, showBounds]);

    // Ensures control only appears once
    React.useEffect(() => {
        map.pm?.removeControls();
        if (map && showControls && currentProject && !disabled) {
            map.pm.addControls({
                position: 'topright',
                drawPolyline: false,
                drawText: false,
                drawCircle: false,
                drawCircleMarker: false,
                drawMarker: false,
                rotateMode: false,
            });
            removeDrawActions();
            initDrawActions();
        }
    }, [map, projectBoundaries, currentProject, showControls, disabled]);

    return <></>;
};
